<template>
    <div>
      <PageContent></PageContent>
    </div>
  </template>
  
  <script>
  
  import 'bootstrap/dist/css/bootstrap.min.css'
  import 'bootstrap-icons/font/bootstrap-icons.css'
  import 'bootstrap'
  import '../assets/scss/app.css'
  import PageContent from './PageContent.vue'
  
  export default {
    name: 'IndexPage',
    components: {
      PageContent
    }
  }
  </script>
  
  <style>
  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  </style>
  