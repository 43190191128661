<template>
    
  <section class="top-section position-relative">
    <div class="overlay"></div>
    <div class="top-section-content">
      <h1 class="display-1">Meet Trang And Her Team</h1>
    </div>
  </section>
  <section class="section-padding-y position-relative">
    <div class="container pt-5">
      <div class="row">
        <div class="col-4 pt-5 mt-5 d-flex flex-column">
          <h1 class="display-1 mt-auto text-dark">So, Who <br />Am I?</h1>
        </div>
        <div class="col-8">
          <img
            class="img-fluid img-thumbnail shadow-lg position-relative about-image"
            src="../assets/img/trang-homepage-large.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
    <img
      class="img-fluid position-absolute about-crest-logo"
      src="../assets/svg/crest-logo.svg"
      alt=""
    />
  </section>

  <section>
        <div class="container">
            <div class="row">
                <div class="text-columns">
                    <p>Trang is an accomplished real estate professional who has made a name for herself in the San Francisco Bay Area. Her journey began when she
                        purchased her first condo at just 21 years old, igniting a passion for the industry that has since led her to help over 750 families with their
                        buying, selling, and investing needs.

                        A proud mother of two boys, Trang understands the importance of hard work and dedication. She is determined to maintain a strong work ethic,
                        constantly solving problems, and bringing tremendous positive energy to every transaction. Her clients appreciate her can-do attitude and her
                        willingness to go above and beyond to help them achieve their real estate goals.

                        With a keen eye for design and a talent for home remodeling, Trang is an expert in all aspects of the real estate industry. She is known for her
                        passion to serve her client's best interests, always striving to exceed their expectations and provide them with the highest level of service
                        possible.

                        Over the years, Trang has built a strong team of real estate professionals who share her commitment to excellence. Together, they work tirelessly
                        to ensure that their clients receive the personalized attention they deserve, and that every transaction is handled with the utmost care and
                        professionalism.

                        In addition to her work in the real estate industry, Trang is actively involved in her community. She volunteers her time and resources to support
                        local causes and organizations, and is passionate about giving back to the community that has given her so much.

                        Trang's success in the real estate industry is a testament to her hard work, dedication, and unwavering commitment to her clients. Whether you are
                        buying, selling, or investing in real estate, you can trust Trang and her team to help you achieve your goals and build a brighter future for you
                        and your family.</p>

                </div>
            </div>
        </div>
    </section>

    <section class="section-padding-y">
        <div class="container">
            <div class="row">
                <div class="col-4 text-center pt-3">
                    <h2 class="display-1 fw-bold">
                        850+
                    </h2>
                    <p>Homes Sold</p>
                </div>
                <div class="col-4 text-center py-3">
                    <h2 class="display-1 fw-bold">
                        15+
                    </h2>
                    <p>Years Service</p>
                </div>
                <div class="col-4 text-center pt-3">
                    <h2 class="display-1 fw-bold">
                        700M+
                    </h2>
                    <p>Total Volume</p>
                </div>
            </div>
        </div>
    </section>

    <section class="section-padding-y bg-gold-light shadow-lg">
        <div class="container">
            <div class="row">
                <div class="col text-center">
                    <h1 class="display-4">Changing the Real Estate World!</h1>
                    <p class="w-50 mx-auto mt-4 lead">As someone who has built a successful real estate career through hard work and dedication, I'm passionate about
                        sharing my knowledge and experience
                        with others. Whether it's by mentoring new agents or by speaking at industry events, I believe that we all have a responsibility to help each
                        other succeed in this ever-changing industry.</p>
                </div>
            </div>
        </div>
    </section>

    <section class="section-padding-y">
        <div class="container pt-5">
            <div class="row">
                <div class="col-6" style="height:600px">
                    <div class="background-image" style="background-image: url('./assets/img/demo/homes/house2.jpg')"></div>
                </div>

                <div class="col-6 pt-5">
                    <h1 class="display-1">My Success Stories</h1>
                    <p class="mt-4">I believe that the best way to demonstrate my expertise is by sharing my past record with you. I'm proud to say that I've had numerous
                        successes in
                        the industry, and I'd like to highlight some of them to you. For example, I've sold a significant number of homes, and I have a track record of
                        achieving high selling prices for my clients. Additionally, I've been able to keep my average days on market low, which shows that I have a strong
                        understanding of the market and the needs of my clients. By sharing my past record with you, I hope to establish myself as a knowledgeable and
                        trustworthy agent who can deliver results. I'm confident that I have the skills and experience necessary to help you achieve your real estate
                        goals, and I'd be honored to work with you</p>
                    <a href="#" class="btn btn-primary my-5">READ MORE</a>
                </div>
            </div>
        </div>
    </section>

    <section class="bg-info-light section-padding-y position-relative overflow-hidden">
        <div class="position-absolute top-0 h-100 w-100 opacity-25">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
                <path class="wave-fill"
                      d="m-110.04,618.5c399.07-180.47,705.46-194.81,914.03-173,178.7,18.69,213.26,56.36,393.26,54,72.53-.95,461.24-6.05,772.58-259,171.9-139.66,218.26-272.04,257.17-257,56.56,21.87-49.04,298.75-127.51,818-53.96,357.06-25.12,365.66-67.51,438-290.52,495.81-1893.79,515.63-2178.45-71-44.7-92.11-88.36-264.03,36.43-550Z"/>
                <path class="wave-fill"
                      d="m-56.76,231.97C46.34,340.63,453.52-62.22,1119.81-35.75c147.6,5.86,221.24,29.35,226.77,11.32,18.19-59.31-714.07-524.27-1145.76-266.38C-25.4-155.66-130.07,154.7-56.76,231.97Z"/>
            </svg>
        </div>
        <div class="container">
            <div class="row">
                <div class="col text-center">
                    <h2 class="display-1 text-dark my-5 text-capitalize">Our Team is Ready To help</h2>
                </div>
            </div>
            <div class="row g-3">
                <div class="col-md-4">
                    <div class="background-image rounded shadow-lg"
                         :style="{ backgroundImage: 'url(' + require('@/assets/img/demo/agents/agent2.png') + ')' }"></div>
                    <div class="my-2 ms-2 text-center">
                        <h3 class="p-0 m-0 fw-bold">Gary Studman</h3>
                        <p>Fremont Agent</p>
                        <div class="d-flex justify-content-around px-5">
                            <i class="bi bi-envelope"></i>
                            <i class="bi bi-phone"></i>
                            <i class="bi bi-twitter"></i>
                            <i class="bi bi-facebook"></i>
                            <i class="bi bi-instagram"></i>
                            <i class="bi bi-youtube"></i>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="background-image rounded"
                        :style="{ backgroundImage: 'url(' + require('@/assets/img/demo/agents/agent3.png') + ')' }"></div>
                    <div class="my-2 ms-2 text-center">
                        <h3 class="p-0 m-0 fw-bold">Lisa Sellsmore</h3>
                        <p>Fremont Agent</p>
                        <div class="d-flex justify-content-around px-5">
                            <i class="bi bi-envelope"></i>
                            <i class="bi bi-phone"></i>
                            <i class="bi bi-twitter"></i>
                            <i class="bi bi-youtube"></i>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="background-image rounded"
                    :style="{ backgroundImage: 'url(' + require('@/assets/img/demo/agents/agent5.png') + ')' }"></div>
                    <div class="my-2 ms-2 text-center">
                        <h3 class="p-0 m-0 fw-bold">Karen Hashomes</h3>
                        <p>Fremont Agent</p>
                        <div class="d-flex justify-content-around px-5">
                            <i class="bi bi-envelope"></i>
                            <i class="bi bi-phone"></i>
                            <i class="bi bi-twitter"></i>
                            <i class="bi bi-youtube"></i>
                            <i class="bi bi-tiktok"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>


</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";
import "../assets/scss/app.css";

export default {
  name: "AboutMe",
  components: {},
};
</script>

<style scoped>
.top-section{
    background-image: url("~@/assets/img/demo/ai-pool.png");
}

.background-image{
    height:400px;
}

.about-crest-logo{
  top:200px;
  left:20%;
  opacity: .04;
  width:800px;
  z-index: 1;
}

.about-image{
  z-index: 10;
}
</style>
