<template>
  <div id="smooth-wrapper">
    <div id="smooth-content">
      <PageHeader></PageHeader>
      <router-view />
      <PageFooter></PageFooter>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";
import "./assets/scss/app.css";
import "./assets/scss/style.css";
import PageHeader from "./components/PageHeader.vue";
// import PageContent from './components/PageContent.vue'
import PageFooter from "./components/PageFooter.vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

export default {
  components: {
    PageHeader,
    PageFooter,
  },
  methods: {
    scroll() {
      ScrollSmoother.create({
        smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
        effects: true, // looks for data-speed and data-lag attributes on elements
        smoothTouch: 0.1, // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
      });
      ScrollTrigger.normalizeScroll(true);
    },
  },
  mounted() {
    // this.scroll();
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
