<template>
  <div class="about-me-container">
    <div class="about-me-grid-item">
      <h2>Meet Trang Dunlap</h2>
      <h3 class="">Intero Real Estate Services</h3>
    </div>
    <div class="about-me-grid-item">
      <p class="">
        As a realtor, I provide a range of valuable services to my clients. I
        have extensive knowledge of the local housing market, which helps me
        determine the best price for a property. I also have expertise in the
        buying and selling process, ensuring that the transaction goes smoothly
        and without any costly mistakes. I provide guidance on preparing a
        property for sale and can help buyers find the perfect home based on
        their specific needs and budget. Overall, my services are essential for
        anyone looking to navigate the complex world of real estate.
      </p>

      <div>
        <about-me-button path="/about-me" iconName="bi bi-arrow-right"
          >MORE ABOUT TRANG
        </about-me-button>
      </div>
    </div>
    <div class="about-me-grid-item position-relative">
      <img
        class="about-me-image"
        src="../assets/img/trang-white-cutout.png"
        alt="Image of Trang Dunlap"
      />
      <img
        class="bg-arrow"
        src="../assets/img/dot-arrow.png"
        alt="background arrow graphic"
      />
    </div>
  </div>
</template>

<script>
import AboutMeButton from "./Button";
export default {
  name: "AboutMeSection",
  components: {
    AboutMeButton,
  },
};
</script>
