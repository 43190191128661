<template>
  <section class="top-section position-relative">
    <div class="overlay"></div>
    <div class="top-section-content">
      <h1 class="display-1">Our Real Estate Services</h1>
    </div>
  </section>

  <section class="py-5 my-5">
    <div class="container mt-5 position-relative">
      <div class="row">
        <div class="col-12 col-md-6 px-5">
          <h2 class="py-4">Maximize Your Home's Potential</h2>
          <p class="lead text-secondary">
            Expertly Guided Home Selling for Maximum Profit
          </p>
          <p class="pb-5">
            Make the most of your home's potential with a realtor who
            specializes in unparalleled marketing strategies and personalized
            attention. When it's time to sell your home, you deserve a real
            estate professional who showcases your property with exceptional
            marketing techniques to reach the right buyers, utilizes detailed
            market analysis to determine the best pricing strategy, provides
            expert guidance throughout the entire sales process, and negotiates
            on your behalf to maximize your profits. Don't leave your home's
            value to chance. Partner with a realtor who is committed to
            achieving the best possible outcome for you.
          </p>
          <a class="btn btn-primary" href="#">CONTACT US</a>
        </div>
        <div class="col-12 col-md-6">
          <img
            src="../assets/img/sold-sign.jpeg"
            alt=""
            class="img-fluid shadow-lg rounded"
          />
        </div>
      </div>
    </div>
  </section>

  <section class="py-5 my-5 position-relative bg-info-light">
    <div class="container-fluid container-xl mt-5">
      <div class="row">
        <div class="col-12 col-md-6 position-relative">
          <img
            class="img-fluid shadow-lg rounded"
            src="../assets/img/family-holding-sold-sign.jpeg"
            alt=""
          />
        </div>
        <div class="col-12 col-md-6 px-5 pt-5 pt-sm-0">
          <h2>Your Dream Home Awaits</h2>
          <p class="lead text-secondary py-2">
            Stress-Free Home Buying with a Realtor Who Cares
          </p>
          <p class="pb-5">
            Discover the joy of seamless home buying with a dedicated realtor
            who puts YOU first! I offer personalized home searches,
            comprehensive market knowledge, skilled negotiations, and a reliable
            support network of trusted professionals. With my tailored services
            and expert guidance, I make the journey to your dream home as smooth
            as possible.
          </p>
          <a class="btn btn-primary" href="#">Let's Get Started</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";
import "../assets/scss/app.css";

export default {
  name: "ServicesPage",
  components: {},
};
</script>

<style scoped>
.top-section {
  background-image: url("~@/assets/img/demo/ai-pool.png");
}

.background-image {
  height: 400px;
}

.image-stack {
  height: 600px;
}

.image-stack img:first-child {
  z-index: 1;
  position: absolute;
  transform: rotate(0.03turn);
}

.image-stack img:last-child {
  z-index: 2;
  position: absolute;
  bottom: 100px;
  left: 60px;
  width: 70%;
  height: 300px;
  object-fit: cover;
  object-position: center;
  transform: rotate(-0.05turn);
}
</style>
