<template>
  <nav class="navbar shadow-sm fixed-top navbar-expand-lg bg-light">
    <div class="container">
      <a class="navbar-brand" href="/">
        <img
          class="img-fluid"
          src="../assets/img/logos/crest-logo-trang-dunlap-group.png"
          style="width: 250px"
          alt="Trang Dunlap Logo"
        />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-xl-end align-items-center"
        id="navbarNav"
      >
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link
              to="/about-me"
              class="nav-link active"
              aria-current="page"
            >
              About Me
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/our-services"
              class="nav-link active"
              aria-current="page"
            >
              Services
            </router-link>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              target="_blank"
              href="https://trang.realscout.com/homesearch/map"
            >
              Search For Homes
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">Resources</a>
          </li>

          <contact-button path="#" iconName="bi bi-send-fill"
            >CONTACT ME</contact-button
          >
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import ContactButton from "./Button.vue";
export default {
  components: {
    ContactButton,
  },
};

let prevScrollpos = window.pageYOffset;
window.onscroll = function () {
  let currentScrollpos = window.pageYOffset;
  if (prevScrollpos > currentScrollpos) {
    document.querySelector(".navbar").classList.add("navbar-show");
    document.querySelector(".navbar").classList.remove("navbar-scroll");
  } else {
    document.querySelector(".navbar").classList.add("navbar-scroll");
    document.querySelector(".navbar").classList.remove("navbar-show");
  }
  prevScrollpos = currentScrollpos;
};
</script>

<style>
.nav-item {
  padding: 0px 10px;
}

.navbar-scroll {
  transform: translateY(-100%);
  transition: transform 0.3s ease-out;
}

.navbar-show {
  transform: translateY(0);
  transition: transform 0.3s ease-out;
}
</style>
