<template>
  <footer class="pt-md-5 mt-md-5">
    <img
      class="img-fluid position-absolute crest-logo"
      src="../assets/svg/crest-logo.svg"
      alt=""
    />

    <div class="footer-container">
      <div class="grid-item">
        <img
          class="img-fluid"
          src="../assets/img/logos/crest-logo-trang-dunlap-group.png"
          alt=""
        />
        <hr />
        <p>
          Working with Trang Dunlap was a great experience from start to finish.
          They took the time to understand our needs and preferences and worked
          tirelessly to find us our dream home. Their knowledge of the local
          market and negotiating skills were invaluable in helping us get the
          best possible deal.
        </p>
      </div>
      <div class="grid-item">
        <h5 class="mb-2">About Us</h5>
        <ul class="list-unstyled">
          <li class="mb-2">
            <a href="#" class="nav-link p-0 text-muted">Home</a>
          </li>
          <li class="mb-2">
            <a href="#" class="nav-link p-0 text-muted">Features</a>
          </li>
          <li class="mb-2">
            <a href="#" class="nav-link p-0 text-muted">Pricing</a>
          </li>
        </ul>
      </div>
      <div class="grid-item">
        <h5 class="mb-2">Services</h5>
        <ul class="list-unstyled">
          <li class="mb-2">
            <a href="#" class="nav-link p-0 text-muted">Home</a>
          </li>
          <li class="mb-2">
            <a href="#" class="nav-link p-0 text-muted">Features</a>
          </li>
          <li class="mb-2">
            <a href="#" class="nav-link p-0 text-muted">Pricing</a>
          </li>
        </ul>
      </div>
      <div class="grid-item">
        <h5 class="mb-2">Resources</h5>
        <ul class="list-unstyled">
          <li class="mb-2">
            <a href="#" class="nav-link p-0 text-muted">Home</a>
          </li>
          <li class="mb-2">
            <a href="#" class="nav-link p-0 text-muted">Features</a>
          </li>
          <li class="mb-2">
            <a href="#" class="nav-link p-0 text-muted">Pricing</a>
          </li>
          <li class="mb-2">
            <a href="#" class="nav-link p-0 text-muted">FAQs</a>
          </li>
          <li class="mb-2">
            <a href="#" class="nav-link p-0 text-muted">About</a>
          </li>
        </ul>
      </div>
      <div class="grid-item">
        <div class="contact-info">
          <h3 class="mb-2">Trang Dunlap</h3>
          <ul class="list-unstyled">
            <li class="mb-2"><i class="bi bi-phone me-2"></i>888.422.9991</li>
            <li class="mb-2">
              <i class="bi bi-envelope me-2"></i
              ><a
                class="text-dark text-decoration-none"
                href="mailto:agent@example.com"
                >trangsf@gmail.com</a
              >
            </li>
            <li class="mb-2">
              <i class="bi bi-geo-alt me-2"></i>123 Main St, Fremont CA 94566
            </li>
            <li class="mb-2">DRE #11254585</li>
          </ul>
        </div>
      </div>
    </div>

    <hr />
    <div class="copyright">
      <p>© 2023 Community Equity Group, Inc. All rights reserved.</p>
      <div class="w-100">
        <i class="bi bi-envelope me-3"></i>
        <i class="bi bi-phone me-3"></i>
        <i class="bi bi-twitter me-3"></i>
        <i class="bi bi-youtube me-3"></i>
        <i class="bi bi-tiktok me-3"></i>
        <i class="bi bi-facebook me-3"></i>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "pageFooter",
};
</script>

<style scoped>
.crest-logo {
  top: 0;
  right: 0;
  opacity: 0.04;
}
</style>
