<template>
  <div class="gsap-container position-relative">
    <!-- <div class="listing position-relative">
      <img src="/assets/img/demo/homes/house1.jpg" alt="" />
    </div> -->
    <div class="circle">
      <span>LEARN MORE</span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "GsapTest",
  methods: {},
  mounted() {
    const gsap = window.gsap;
    gsap.set(".circle", {
      backgroundColor: "white",
      width: 50,
      height: 50,
    });

    console.log(mouseX);

    document.addEventListener("mousemove", function (e) {
      const mx = e.clientX;
      const my = e.clientY;
      const mcenter = { x: mx, y: my };
      const tl = gsap.timeline();
      const circle = document.querySelector(".circle");
      let cx;
      let cy;

      tl.to(circle, {
        x: mx - 25,
        y: my - 25,
        duration: 1,
        borderRadius: "50%",
        onUpdate: function () {
          // Get the new position of the circle
          cx = circle.getBoundingClientRect().left;
          cy = circle.getBoundingClientRect().top;
          const center = { x: cx + 25, y: cy + 25 };

          // Calculate the distance between the mouse and the circle
          const dist = Math.sqrt(
            Math.pow(mcenter.x - center.x, 2) +
              Math.pow(mcenter.y - center.y, 2)
          );

          //if the distance is less that the radius of the circle, change the color
          if (dist < 10) {
            gsap.to(".circle", {
              backgroundColor: "lightgrey",
              borderRadius: "0%",
              width: 200,
              duration: 0.25,
              opacity: 1,
            });
          } else {
            gsap.to(".circle", {
              backgroundColor: "white",
              duration: 0.25,
              opacity: 0,
            });
          }
        },
      });
    });
  },
};
</script>

<style scoped>
.gsap-container {
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
</style>
