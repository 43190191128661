import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import IndexPage from './components/IndexPage.vue'
import AboutMe from './components/AboutMe.vue'
import ServicesPage from './components/ServicesPage.vue'
import PageHeader from './components/PageHeader.vue'
import PageContent from './components/PageContent.vue'
import PageFooter from './components/PageFooter.vue'
import GsapTestPage from './components/GsapTest.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', component: IndexPage },
    { path: '/about-me', component: AboutMe },
    { path: '/our-services', component: ServicesPage },
    { path: '/gsap', component: GsapTestPage }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

const app = createApp(App)

app.component('PageHeader', PageHeader)
app.component('PageContent', PageContent)
app.component('PageFooter', PageFooter)

app.use(router)

app.mount('#app')
