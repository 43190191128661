<template>
  <div class="testimonial-container shadow-lg">
    <div>
      <h3 class="card-title mb-3">Outstanding Experience with Trang Dunlap</h3>
      <p class="card-text limit-words">
        My experience working with Trang Dunlap was exceptional. Their attention
        to detail and personalized approach made the process of buying a home a
        breeze. They listened to our needs and preferences, and their extensive
        knowledge of the local market helped us find the perfect home in no
        time. Their communication and follow-up throughout the process were
        excellent, and they were always available to answer any questions we
        had. Their negotiating skills also helped us get a great deal on our new
        home. We couldn't be happier with the outcome and would highly recommend
        [Agent Name] to anyone in search of a dedicated and knowledgeable real
        estate agent.
      </p>
      <div class="testimonial-footer">
        <div class="d-inline-block">
          <i class="bi bi-star-fill text-warning"></i>
          <i class="bi bi-star-fill text-warning"></i>
          <i class="bi bi-star-fill text-warning"></i>
          <i class="bi bi-star-fill text-warning"></i>
          <i class="bi bi-star-fill text-warning"></i>
        </div>
        <p class="card-text">
          <small class="text-muted">- Stacy Gen</small>
        </p>
      </div>
    </div>
    <div>
      <img
        src="../../public/assets/img/demo/homes/house1.jpg"
        alt="Homeowner Image"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "TestimonialSection",
  components: {},
};
</script>
