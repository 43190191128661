<template>
  <div
    class="hero"
    :style="{ backgroundImage: 'url(' + backgroundImage + ')' }"
  >
    <div class="hero-content">
      <h1 class="page-title" id="pageTitle">
        Hello, I'm Trang,<br />
        So good to meet you!
      </h1>
      <!-- <h3 class="page-sub-title">
        I help people, just like you, build wealth and prosperity through real
        estate.
        <strong class="fw-bold">I'd love to help you</strong>.
      </h3> -->
      <div class="button-wrapper">
        <div>
          <contact-button>CONTACT ME</contact-button>
          <a
            target="_blank"
            href="https://trang.realscout.com/homesearch/map"
            class="fw-bold text-decoration-none text-dark ms-5"
          >
            Start your home Search
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin(SplitText);
import ContactButton from "./Button.vue";
export default {
  name: "HeroSection",
  components: {
    ContactButton,
  },
  data() {
    return {
      backgroundImage: require("@/assets/img/trang-homepage-large-right.jpg"),
    };
  },
  methods: {
    loadAnim() {
      const st = {
        trigger: ".hero",
        start: "top top",
        end: "bottom top",
        scrub: 1,
      };

      gsap.to(".hero", {
        scrollTrigger: st,
        backgroundPositionY: "400px",
        ease: "none",
      });

      gsap.to(".page-title", {
        scrollTrigger: st,
        y: 400,
      });

      gsap.to(".button-wrapper div", {
        scrollTrigger: st,
        y: -400,
      });
    },
  },
  computed: {
    heroBackgroundImageStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
        // backgroundSize: "cover",
      };
    },
  },
  mounted() {
    // this.loadAnim();
  },
};
</script>

<style scoped></style>
