<template>
  <router-link :to="path" :class="classes">
    <slot></slot>
    <span class="shadow-sm">
      <i :class="iconClass"></i>
    </span>
  </router-link>
</template>

<script>
export default {
  name: "MyButton",
  props: {
    path: {
      type: String,
      default: "/",
    },
    iconName: {
      type: String,
      default: "bi bi-send-fill",
    },
  },
  computed: {
    classes() {
      return {
        "btn-rounded": true,
        "fw-bold": true,
        "rounded-pill": true,
      };
    },
    iconClass() {
      return `${this.iconName} text-dark`;
    },
  },
};
</script>
