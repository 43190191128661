<template>
  <div class="container-fluid service-container">
    <div class="expose">
      <div class="container">
        <div class="row">
          <div class="col text-center my-4">
            <h2>The Trang Dunlap Advantage</h2>
            <p class="lead">
              Dedication and Experience Working for your best interest
            </p>
            <div class="w-100 text-center">
              <hr class="mx-auto text-primary w-50 border-1 border-primary" />
            </div>
          </div>
        </div>
        <div class="row pb-4">
          <div class="col-6 col-md-4 text-center pt-3 p-4 p-md-0">
            <div class="result"><h2>850+</h2></div>
            <p>Homes Sold</p>
          </div>
          <div class="col-6 col-md-4 text-center py-3 p-4 p-md-0">
            <div class="result"><h2>15+</h2></div>
            <p>Years Service</p>
          </div>
          <div class="col-12 col-md-4 text-center pt-3 p-4 p-md-0">
            <div class="result"><h2>700M+</h2></div>
            <p>Total Volume</p>
          </div>
        </div>
      </div>

      <div class="container py-5 flex-row justify-content-center">
        <div class="service-grid-container">
          <div class="service-grid-item">
            <h3>Residential Sales</h3>
            <p>
              Specializing in residential property sales, we guide clients
              through the entire process, from property search to closing. Our
              expertise covers various property types, such as single-family
              homes, townhouses, and condos. We ensure our clients make informed
              decisions by providing personalized advice, market insights, and
              pricing strategies.
            </p>
            <div>
              <a href="#" class="btn">LEARN MORE</a>
            </div>
          </div>
          <div class="service-grid-item">
            <h3>Relocation Services</h3>
            <p>
              We provide comprehensive relocation assistance for clients moving
              within or to the area. Our services include identifying suitable
              neighborhoods, finding homes that meet specific requirements, and
              facilitating
            </p>
            <div>
              <a href="#" class="btn">LEARN MORE</a>
            </div>
          </div>
          <div class="service-grid-item">
            <h3>Real Estate Investment</h3>
            <p>
              We actively partner with clients in buying and selling investment
              properties, going beyond just providing advice. Our expertise
              covers sourcing lucrative opportunities, conducting thorough due
              diligence, and managing transactions to help clients build and
              diversify their real estate portfolios. By working closely with
              investors.
            </p>
            <div>
              <a href="#" class="btn">LEARN MORE</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
export default {
  name: "ServiceSection",
  components: {},
  data() {
    return {
      backgroundImage: require("@/assets/svg/crest-logo.svg"),
    };
  },
  methods: {
    /* eslint-disable */
    loadAnim() {
      gsap.from(".result h2", {
        scrollTrigger: {
          trigger: ".service-container",
          start: "top top",
          end: "bottom top",
          // scrub: 1,
          pin: true,
          // markers: true,
        },
        y: "100%",
        stagger: 0.25,
      });
    },
  },
  mounted() {
    // this.loadAnim();
  },
};
</script>

<style scoped></style>
