<template>
  <div class="contact-container shadow-lg">
    <div class="grid-item">
      <div class="contact-image">
        <img
          src="../assets/img/trang-contact.jpg"
          alt="Image of Trang Dunlap"
        />
      </div>
      <div class="contact-info">
        <h3>Trang Dunlap</h3>
        <p>
          Contact me today and we'll explore
          <strong>all your opportunities</strong> so you can live the life you
          dream about!
        </p>

        <ul class="list-unstyled">
          <li><i class="bi bi-phone me-2"></i>888.422.9991</li>
          <li>
            <i class="bi bi-envelope me-2"></i
            ><a class="text-decoration-none" href="mailto:agent@example.com"
              >trangsf@gmail.com</a
            >
          </li>
          <li>
            <i class="bi bi-geo-alt me-2"></i>123 Main St, Fremont CA 94566
          </li>
          <li>DRE #11254585</li>
        </ul>

        <div class="contact-icons">
          <i class="bi bi-facebook me-3"></i>
          <i class="bi bi-youtube me-3"></i>
          <i class="bi bi-instagram me-3"></i>
        </div>
      </div>
    </div>
    <div class="grid-item">
      <div class="pb-5 form-header">
        <h2>Contact Me</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit.
          Perspiciatis, iste?
        </p>
      </div>
      <div class="container">
        <form action="#" method="post">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label for="name" class="form-label">Your Name</label>
              <input
                type="text"
                class="form-control"
                id="name"
                name="name"
                required
              />
            </div>
            <div class="col-md-6 mb-3">
              <label for="email" class="form-label">Your Email</label>
              <input
                type="email"
                class="form-control"
                id="email"
                name="email"
                required
              />
            </div>
          </div>
          <div class="mb-3">
            <label for="subject" class="form-label">Subject</label>
            <input
              type="text"
              class="form-control"
              id="subject"
              name="subject"
              required
            />
          </div>
          <div class="mb-3">
            <label for="message" class="form-label">Message</label>
            <textarea
              class="form-control h-100"
              id="message"
              name="message"
              rows="5"
              required
            ></textarea>
          </div>
          <button type="submit" class="btn text-uppercase">Send Message</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactMeSection",
};
</script>
