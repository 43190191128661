<template>
  <hero-section></hero-section>

  <div class="main">
    <section id="services">
      <service-section></service-section>

      <!-- <services-offered></services-offered> -->
    </section>
    <!-- <listings-scroll></listings-scroll> -->

    <section style="padding-top: 5rem">
      <about-me-section></about-me-section>

      <div class="container d-none d-sm-block">
        <div class="row py-5">
          <div class="col">
            <img
              class="img-fluid"
              src="../assets/img/logos/intero.png"
              alt=""
            />
          </div>
          <div class="col">
            <img
              class="img-fluid"
              src="../assets/img/logos/luxury.png"
              alt=""
            />
          </div>
          <div class="col">
            <img
              class="img-fluid"
              src="../assets/img/logos/zillow.png"
              alt=""
            />
          </div>
          <div class="col">
            <img
              class="img-fluid"
              src="../assets/img/logos/americas-best.png"
              alt=""
            />
          </div>
          <div class="col">
            <img
              class="img-fluid"
              src="../assets/img/logos/leading-100.png"
              alt=""
            />
          </div>
          <div class="col">
            <img
              class="img-fluid"
              src="../assets/img/logos/business-insider.png"
              alt=""
            />
          </div>
          <div class="col">
            <img
              class="img-fluid"
              src="../assets/img/logos/realscout.png"
              alt=""
            />
          </div>
          <div class="col">
            <img
              class="img-fluid"
              src="../assets/img/logos/silicon-bank.png"
              alt=""
            />
          </div>
          <div class="col">
            <img class="img-fluid" src="../assets/img/logos/tan.png" alt="" />
          </div>
        </div>
      </div>
    </section>

    <!--region SuccessStories-->
    <!-- <section class="bg-info-light sticky-card-container">
      <div class="container">
        <div class="row">
          <div class="col">
            <h2>Success Stories</h2>
            <p class="lead">
              Just a few of our many sales that left our clients shouting with
              joy
            </p>
          </div>
        </div>
      </div>
  
      <div
        v-for="index in 3"
        :key="index"
        class="success-story-card bg-info-light"
      >
        <div class="shadow">
          <div class="container">
            <div class="flex-row d-flex my-5">
              <div class="w-50 me-2" style="height: 400px">
                <div
                  class="background-image"
                  style="
                    background-image: url(/assets/img/demo/homes/house1.jpg);
                  "
                ></div>
              </div>

              <div class="w-25 me-2" style="height: 400px">
                <div
                  class="background-image"
                  style="
                    background-image: url(./assets/img/demo/homes/house2.jpg);
                  "
                ></div>
              </div>

              <div class="w-25">
                <div class="p-3">
                  <div class="">
                    <h3 class="fw-bold">1234 Rainbow St.</h3>
                    <p>Oakland, CA 94571</p>
                  </div>
                  <p>
                    Welcome to your dream home! This beautiful property boasts a
                    range of features designed to provide you with the ultimate
                    living experience. From the spacious living area to the
                    fully equipped kitchen, every inch of this home has been
                    meticulously crafted to meet your needs. The bedrooms and
                    bathrooms provide ample space and comfort, ensuring that you
                    can relax in style.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </section> -->
    <!--endregion Success Stories-->

    <section
      class="background-image position-relative py-md-5 shadow-lg"
      style="background-image: url('/assets/img/demo/homes/house3.jpg')"
    >
      <div class="dark-overlay"></div>
      <div class="container py-5 position-relative">
        <div class="row h-100 align-items-center">
          <div class="col-md-8 mx-auto text-center text-white">
            <h1 class="display-1 text-white">Start Your Search With Me</h1>
            <p class="py-4 fs-4">
              Finding your dream home is a collaborative process, and our team
              is here to guide you every step of the way. With RealScout's
              innovative search platform and our experienced agents, we'll work
              together to find the perfect home that meets your needs and
              exceeds your expectations. Let's collaborate today and start your
              home search with confidence.
            </p>
            <div
              class="realscout-search simple mb-5"
              data-rep="trang"
              data-button-color="#AB2C02"
              data-button-font="#ffffff"
              data-background-color="#eee"
            ></div>
          </div>
        </div>
      </div>
    </section>

    <section style="margin: 100px 0" class="d-none d-lg-block">
      <div class="container">
        <div class="grid-container shadow-lg">
          <div class="grid-1">
            <img
              src="../assets/img/demo/buying.jpeg"
              class="img-fluid"
              alt=""
            />
          </div>

          <div class="w-100 grid-3 p-3 bg-light">
            <h2 class="w-100 display-2 text-center">
              Save Money When Buying and Selling
            </h2>
            <p class="p-5">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Blanditiis deleniti dolore eaque esse ex incidunt ipsum recusandae
              repudiandae, similique sit sunt totam ullam! Aliquam aperiam
              distinctio et, illum ipsam reiciendis. Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Ad aliquid architecto blanditiis
              deleniti deserunt doloribus earum eligendi hic, laboriosam
              pariatur perspiciatis quam rem similique soluta voluptate! Earum
              nostrum quae repellendus.
            </p>
            <div class="w-100 text-end">
              <a href="#" class="btn btn-primary me-5">LEARN MORE</a>
            </div>
          </div>

          <div class="h-100 bg-secondary text-white grid-4 p-5">
            <div class="h-100 w-100 flex-column d-flex justify-content-between">
              <h2 class="display-3">Sell Your Home</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad
                autem commodi cumque numquam? Aperiam laudantium nulla unde.
                Aperiam atque deserunt earum et laudantium maiores
                necessitatibus odit placeat quaerat temporibus. Est.
              </p>
              <a href="#" class="btn btn-light mt-4">LEARN MORE</a>
            </div>
          </div>

          <div class="bg-dark text-white grid-2 p-5">
            <div class="h-100 flex-column d-flex justify-content-between">
              <h2 class="display-3">Buying a Home</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Accusantium, architecto aspernatur aut blanditiis dolorem eos ex
                fugit ipsam laboriosam laborum nam necessitatibus nemo odio
                optio quaerat repellendus soluta suscipit voluptate!
              </p>
              <a href="#" class="btn btn-light mt-4">LEARN MORE</a>
            </div>
          </div>
          <div class="grid-5">
            <img
              src="../assets/img/demo/relocation.jpeg"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>

    <section id="testimonials">
      <!-- <div class="overlay"></div> -->
      <div class="overlay" style="transform: rotateY(0.5turn)">
        <svg
          id="Layer_1"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1920 1080"
        >
          <path
            class="wave-fill"
            d="m-110.04,618.5c399.07-180.47,705.46-194.81,914.03-173,178.7,18.69,213.26,56.36,393.26,54,72.53-.95,461.24-6.05,772.58-259,171.9-139.66,218.26-272.04,257.17-257,56.56,21.87-49.04,298.75-127.51,818-53.96,357.06-25.12,365.66-67.51,438-290.52,495.81-1893.79,515.63-2178.45-71-44.7-92.11-88.36-264.03,36.43-550Z"
          />
          <path
            class="wave-fill"
            d="m-56.76,231.97C46.34,340.63,453.52-62.22,1119.81-35.75c147.6,5.86,221.24,29.35,226.77,11.32,18.19-59.31-714.07-524.27-1145.76-266.38C-25.4-155.66-130.07,154.7-56.76,231.97Z"
          />
        </svg>
      </div>
      <div class="container-fluid container-xxl position-relative">
        <div class="row">
          <div class="col py-3 py-md-5">
            <h2>Testimonials</h2>
            <p>Raving Reviews from clients we have helped over the years</p>
          </div>
        </div>
        <TestimonialSection></TestimonialSection>
      </div>
    </section>

    <section class="bg-white py-5">
      <div class="container">
        <contact-me-section></contact-me-section>
      </div>
    </section>
  </div>
</template>

<script>
import TestimonialSection from "./TestimonialSection.vue";
import AboutMeSection from "./AboutMeSection.vue";
import ContactMeSection from "./ContactMeSection.vue";
// import ListingsScroll from "./ListingsScrollSection.vue";
import HeroSection from "./HeroSection.vue";
import ServiceSection from "./ServiceSection.vue";
// import ServicesOffered from "./ServicesOffered.vue";
// import ExclusiveListings from "./ExclusiveListings.vue";
export default {
  name: "PageContent",
  components: {
    TestimonialSection,
    AboutMeSection,
    ContactMeSection,
    // ListingsScroll,
    HeroSection,
    ServiceSection,
    // ServicesOffered,
    // ExclusiveListings,
  },
};

(function () {
  var script = document.createElement("script");
  script.className = "rs-embedded-script";
  script.async = true;
  script.src = "https://em.realscout.com/assets/em/v3/all.js";
  var entry = document.getElementsByTagName("script")[0];
  entry.parentNode.insertBefore(script, entry);
})();
</script>
